import React from 'react';
import { graphql, Link } from 'gatsby';
import Seo from "../components/seo"
import { addCart } from '../services/cart'

const WpBookTemplate = ({ data: { wpBook } }) => {

  const { id, title, content, BookDependentInformation } = wpBook;
  const { haserrata, errataurl, contentspdf, size, shipping, price, page, issuedate, memberprice, image } = BookDependentInformation;
  const publicUrlDirectory = image.localFile.publicURL.split('/')[2];

  const add = () => {
    const bookInSettionValue = {
      "id": id,
      "title": title,
      "publicUrl": image.localFile.publicURL,
      "price": price,
      "memberprice": memberprice,
      "count": 1,
    }
    addCart(bookInSettionValue)
  }

  return (
    <>
      <Seo title={title} />
      <div id="pageTitle">
        <h1>出版・刊行物</h1>
      </div>
      <div className="breadClumb">
        <ul>
          <li>
            <Link to="/">
              HOME
            </Link>
          </li>
          <li>
            <Link to="/publication/">
              出版・刊行物
            </Link></li>
          <li>{title}</li>
        </ul>
      </div>
      <div id="main">
        <div className="contents">
          <section className="contents_block">
            <h1 className="header01">{title}</h1>
            <div className="inner">
              <div className="book_box">
                <div className="book_img"><img src={image.localFile.publicURL} alt="" className="imgFit" /></div>
                <div className="book_info">
                  <table className="tableStyle1">
                    <tbody>
                      <tr>
                        <th>サイズ</th>
                        <td>{size}</td>
                      </tr>
                      <tr>
                        <th>ページ</th>
                        <td>{page}</td>
                      </tr>
                      <tr>
                        <th>会員特価</th>
                        <td>{String(memberprice).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}円（税込）</td>
                      </tr>
                      <tr>
                        <th>定価</th>
                        <td>{String(price).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}円（税込）</td>
                      </tr>
                      <tr>
                        <th>送料</th>
                        <td>{String(shipping).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}円</td>
                      </tr>
                      <tr>
                        <th>発行</th>
                        <td>{issuedate}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="book_link">
                  <p className="mb1em" dangerouslySetInnerHTML={{ __html: content }}></p>
                  <ul className="list_style1 mb50" style={{ paddingLeft: '0' }}>
                    <li><a href={contentspdf.url} target="_blank" rel="noopener noreferrer">目次を表示(PDF)</a></li>
                    {haserrata ? <li><a href={errataurl.url} target="_blank" rel="noopener noreferrer">正誤表</a></li> : null}
                  </ul>
                  <div>
                    <Link to={`/addToCart?bookId=${id}&pub=${publicUrlDirectory}`} className="btn_rounded_topLogin" onClick={add}>注文する</Link>
                  </div>
                </div>
              </div>
              <div className="backToNewsList">
                <Link to="/publication">一覧に戻る</Link>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export const query = graphql`
  query($id: String!) {
    wpBook(id: { eq: $id }){
        id
        title
        uri
        content
        BookDependentInformation {
          haserrata
          errataurl {
            url
            }
          contentspdf {
            url
            }
          size
          shipping
          price
          page
          issuedate
          memberprice
          image {
            localFile {
              publicURL
            }
          }
        }
      }
  }
`;

export default WpBookTemplate;